import moment from 'moment';
export default {
  data () {
    return {};
  },
  methods: {
    maskedNumber ( getvalue ) {
      if ( getvalue) {
        const maskedDigits = "X".repeat( getvalue.length - 6 );
        const FirstThreeDigits = getvalue.slice( 0, 3 );
        const lastThreeDigits = getvalue.slice( -3 );
        return FirstThreeDigits + maskedDigits + lastThreeDigits;
      }
    },
    maskedEmail ( getvalue ) {
      const [ name, domain ] = getvalue.split( "@" );
      return `${ name[ 0 ] }${ new Array( name.length ).join( "*" ) }@${ domain }`;
    },
    maskedAadhar ( getvalue ) {
      const maskedDigits = "*";
      const lastThreeDigits = getvalue.slice( -3 );
      return maskedDigits + lastThreeDigits;
    },
    maskedVisa ( val ) {
      const maskedDigits = "*";
      const lastFourDigits = val.slice( -4 );
      return maskedDigits + lastFourDigits;
    },
    format_datetime ( value ) {
      if ( value ) {
        return moment( String( value ) ).format( "DD/MM/YYYY hh:mm a" );
      } else {
        return 'N/A';
      }
    },
    format_date ( value ) {
      if ( value ) {
        return moment( String( value ) ).format( "DD/MM/YYYY" );
      } else {
        return 'N/A';
      }
    },
    format_date_month ( value ) {
      if ( value ) {
        return moment( String( value ) ).format( "DD/MM" );
      } else {
        return 'N/A';
      }
    },
    format_timeonly ( value ) {
      if ( value ) {
        return moment( String( value ) ).format( "hh:mm:ss a" );
      } else {
        return 'N/A';
      }
    },
    format_date_iso ( value ) {
      if ( value ) {
        var localTime = new Date( value * 1000 ).toUTCString();
        return moment( localTime ).format( "DD/MM/YYYY" );
      } else {
        return 'N/A';
      }
    },
    format_timeonly_iso ( value ) {
      if ( value ) {
        let dat = new Date( value * 1000 ).toUTCString();
        let vart = this.convertTimeTo12HourFormat( dat.split( ' ' )[ 4 ].split( ":" )[ 0 ] + ":" + dat.split( ' ' )[ 4 ].split( ":" )[ 1 ] )
        return vart
      } else {
        return 'N/A';
      }
    },
    convertTimeTo12HourFormat ( time24 ) {
      if ( typeof time24 !== 'string' || time24.length !== 5 ) {
        return "Invalid time format";
      }
      var hours24 = parseInt( time24.substr( 0, 2 ) );
      var minutes = time24.substr( 3, 2 );

      var amPm = ( hours24 >= 12 ) ? 'PM' : 'AM';
      var hours12 = ( hours24 % 12 === 0 ) ? 12 : hours24 % 12;

      if ( hours12 === 0 ) {
        hours12 = 12;
      }

      if ( minutes.length === 1 ) {
        minutes = '0' + minutes;
      }

      let hrs1 = String( hours12 ).length === 1 ? ( "0" + hours12 ).toString() : String( 0 );
      return hrs1 + ':' + minutes + ':00 ' + amPm;
    },
    format_timestamp ( value ) {
      if ( value ) {
        var localTime = new Date( value * 1000 ).toUTCString();
        return moment( localTime ).format( "DD/MM/YYYY @ hh:mm a" );
      } else {
        return 'N/A';
      }
    },
    format_dateonlystamp ( value ) {
      if ( value ) {
        var localTime = new Date( value * 1000 ).toUTCString();
        return moment( localTime ).format( "DD/MM/YYYY" );
      } else {
        return 'N/A';
      }
    },
    format_datemonthstamp ( value ) {
      if ( value ) {
        var localTime = new Date( value * 1000 ).toUTCString();
        return moment( localTime ).format( "DD MMM,YYYY" );
      } else {
        return 'N/A';
      }
    },
    format_timeonlystamp ( value ) {
      if ( value ) {
        var localTime = new Date( value * 1000 ).toUTCString();
        return moment( localTime ).format( "hh:mm:ss a" );
      } else {
        return 'N/A';
      }
    },
    onlyNumber ( $event ) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ( keyCode < 48 || keyCode > 57 ) {
        $event.preventDefault();
      }
    },
    isLetterWithSpace ( e ) {
      let charspace = String.fromCharCode( e.keyCode );
      if ( /^[A-Za-z\s]+$/.test( charspace ) ) return true;
      else e.preventDefault();
    },
    isLetterWithOutSpace ( e ) {
      let char = String.fromCharCode( e.keyCode );
      if ( /^[A-Za-z]+$/.test( char ) ) return true;
      else e.preventDefault();
    },
    mobileSearchFormat ( $event ) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ( keyCode < 48 || keyCode > 57 ) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById( "searchmobInput" );
      if (
        ( mobileInput.value == "" || mobileInput.length > 1 ) &&
        ( keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52 )
      ) {
        $event.preventDefault();
      }
    },

    checkEmpty ( field ) { ( !!( ( field !== undefined && field !== null && field !== '' ) ) ) },
    // genderList: [
    //   { label: "Male", value: 1 },
    //   { label: "Female", value: 2 },
    //   { label: "Other", value: 3 },
    // ],
    isAlphaNumeric ( e ) {
      let char = String.fromCharCode( e.keyCode );
      console.log(char)
      if ( /^[a-zA-Z0-9 @&$]+$/.test( char ) ) return true;
      else e.preventDefault();
    },
  },
};
