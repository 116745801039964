import axios from "axios";
export default class ApiService {

  StorageObjUserRef = localStorage.user_reference;
  StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
  client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : '';


  HeaderParam = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "content-type": "application/json",
    "Accept": "application/json"
};

AuthenticatedUserHeader = {
    "Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': "Bearer " + this.StorageObjUserRef,
    'client': this.client,
};
commonapiall = (url, params, method, type, item) => {
  return axios(url, {
      method: method,
      headers: params,
      data: item
  })
      .then(response => {
          if (response.data.status === 403) {
              // if (type === 1) {
              //   logout();
              // }
          } else {
              return response.data;
          }
      })
      .catch(error => {
          throw error;
      });
};

  logoutsession(item) {

      let URL = "/api/u/logoutsession";
      return axios(URL, {
          method: 'POST',
          headers: this.AuthenticatedUserHeader,
          data: item,
      })
          .then(response => {
              localStorage.clear();
              window.location.href = "/";
              return response.data;
          })
          .catch(error => {
              throw error;
          });
  }
  logout(item) {

      localStorage.clear();
      let URL = "/api/v1/logout";
      return axios(URL, {
          method: 'POST',
          headers: this.AuthenticatedUserHeader,
          data: item,
      })
          .then(response => {

              localStorage.clear();
              window.location.href = "/";
              return response.data;
          })
          .catch(error => {
              throw error;
          });
  }

  checkuseraccesscode(item) {
      let URL = "/api/u/checkuseraccesscode";
      return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getCitizenList(item) {
    let URL = "/api/v1/getCitizenList";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  sendCitizenPushNotification(item) {
    let URL = "/api/v1/sendCitizenPushNotification";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getVoterWebBotHistory(item) {
    let url = '/api/u/getVoterWebBotHistory';
    return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
    }
    citizenCampaignList(item) {
        let URL = "/api/v1/citizenCampaignList";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
      }
      getcitizencampaignlogs(item) {
        let URL = "/api/v1/getcitizencampaignlogs";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
      }
    webincomingmsg(item) {
        let url = '/api/u/webincomingmsg';
        return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
    }
    verifyMobileNumber(item) {
        let url = '/api/u/verifyMobileNumber';
        return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
    }
    verifyOtp(item) {
        let url = '/api/u/verifyOtp';
        return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
    }
    getProfessions(item) {
        let url = '/api/getProfessionList';
        return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
    }
    getclientdetails(item) {
        let url = '/api/u/getclientdetails';
        return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
    }
    getCitizenCount(item) {
        let URL = "/api/v1/getCitizenCount";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
      }
      getnotificationcampaigntemplateList(item) {
        let url = '/api/v1/getnotificationcampaigntemplateList';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    }

    getnotificationcampaigntemplates(item) {
        let url = '/api/v1/getnotificationcampaigntemplates';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    }

    addnotificationcampaignTemplate(item) {
        let url = '/api/v1/addnotificationcampaignTemplate';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    }
    updateNotificationCampaignTemplate(item) {
        let url = '/api/v1/updateNotificationCampaignTemplate';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    }
    getlanguages(item) {
        let URL = "/api/v1/common/getlanguages";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
    }
    getcampaigntemplatesList(item) {
        let URL = "/api/v1/getcampaigntemplatesList";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, 'POST', 0, item);
    }
    getTemplateBody(item) {
        let url = '/api/v1/getTemplateBody';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'Post', 0, item);
    }
    addScheduleCampaign(item) {
        let url = '/api/v1/addScheduleCampaign';
        return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
    }
    getcampaign(item) {
        let URL = "/api/v1/getcampaign";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
    getcampaignlogs(item) {
        let URL = "/api/v1/getcampaignlogs";
        return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
    }
}
