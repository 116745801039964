import { createStore } from "vuex";
import ApiService from "../../src/service/ApiService";
const apiService = new ApiService;
import commonFunction from "../composables/commonFunction"
const { decrypt, checkEmpty } = commonFunction()
export default createStore( {

    state: {
        loggedInUserSessions: {
            localstoragepath: null,
            user: null,
            client_info: null,
            user_reference: null,
            permission_fks: null,
            clientfk: null,
            userfk: null
        },

        AuthenticatedUserHeader: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "content-type": "application/json",
            Accept: "application/json",
        },

        billingStateParam: {
            showpaymenttwoloader: false,
            paymentStatus: null
        },
        newtempaltemodalstatus: false
    },

    mutations: {
        updateLoggedInUserSessions ( state, data ) {
            state.loggedInUserSessions.user = data
            state.loggedInUserSessions.localstoragepath = data.client_info.maa24;
            state.loggedInUserSessions.user_reference = data.token;
            state.loggedInUserSessions.permission_fks = data.ad28;
            state.loggedInUserSessions.client_info = data.client_info;
            state.AuthenticatedUserHeader[ 'Authorization' ] = "Bearer " + data.token;
            state.AuthenticatedUserHeader[ 'client' ] = data.client_info.maa1;
            state.AuthenticatedUserHeader[ 'user' ] = JSON.stringify( data );
        },

        updateBillingStateParam ( state, data ) {
            state.billingStateParam.showpaymenttwoloader = !state.billingStateParam.showpaymenttwoloader
            state.billingStateParam.paymentStatus = data
        },
        addCampaignStatus(state) {
            state.newtempaltemodalstatus = !state.newtempaltemodalstatus;
        },
    },

    actions: {
        async checkLoginCredentials ( context ) {
            const client = localStorage && localStorage.client ? decrypt( localStorage.client ) : null;
            const user = localStorage && localStorage.user ? decrypt( localStorage.user ) : null;
            this.state.loggedInUserSessions.clientfk = client;
            this.state.loggedInUserSessions.userfk = user;
            if ( checkEmpty( client ) && checkEmpty( user ) ) {
                if ( !checkEmpty( this.state.loggedInUserSessions.user ) && !checkEmpty( this.state.loggedInUserSessions.user_reference ) ) {
                    return await apiService.loginCache( { ad1: user } ).then( ( data ) => {
                        context.commit( "updateLoggedInUserSessions", data )
                        return data
                    } );
                }
            }
        },

        updateBillingStateParam ( context, payload ) {
            context.commit( "updateBillingStateParam", payload )
        },
        addCampaignStatus(context)
        {
            context.commit("addCampaignStatus");
        }, 
    }
} );