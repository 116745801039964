<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-1 me-0">
        <div class="logo-outer" style="margin-bottom: 27px;">
            <img src="/assets/images/cm-images/goa-gov-logo.png" alt="goa goverment" height="50" />
        </div>
        <div class="logo-outer">
            <img src="/assets/images/cm-images/cm-header-logo.png" height="50" alt="cm" />
          </div>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <div class="menubar-submeanu-outer" v-for="(menuItem, headIndex) in sidebarMenu" :key="headIndex">
            <ul class="nav flex-column" :id="'header-' + headIndex">
              <li class="nav-item">
                <router-link v-if="menuItem.menu" class="nav-link" :to="menuItem.link || ''">
                  <i :class='menuItem.icon' class="navlink-tab-icon"></i>{{ menuItem.menu }}</router-link>
                <button v-else-if="menuItem.menuHeader" class="btn btn-toggle align-items-center collapsed test-collapse"
                  data-bs-toggle="collapse" :data-bs-target="`#${menuItem.menuHeader}`" aria-expanded="false">
                  <i :class='menuItem.icon' class="navlink-tab-icon"></i>{{ menuItem.menuLabel }}
                </button>
                <div v-if="menuItem.sidebarSubMenu" class="collapse" data-bs-parent="#sidebarMenu"
                  :id="menuItem.menuHeader">
                  <ul class="btn-toggle-nav fw-normal small">
                    <li v-for="(subMenu, index) in menuItem.sidebarSubMenu" :key="index">
                      <router-link class="nav-link" :to="subMenu.link"><div class="submenu-active-span">{{ subMenu.submenu }}</div></router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner">
          <div>
            <img class="img-fluid" alt="" src="/assets/images/cm-images/cm-footer-img.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      host_name: "",
      client_info: "",
      client_logo: "",
      baseUrl: window.location.origin,
      permission_fks: localStorage.permission_fks,
      user_info: '',
      whiteimageactivestatus: false,
    blackimageactivestatus: true,
      sidebarMenu: [
        {
          menu: "Dashboard",
          menuLabel: "Dashboard",
          link: "/dashboard",
          img: "/assets/images/cm-menu-icon/m-dashboard.svg",
          icon: "pi pi-th-large"
        },
        {
          menuHeader: "Users",
          menuLabel: "Users",
          img: "/assets/images/cm-menu-icon/m-users.svg",
          icon: "pi pi-user",
          sidebarSubMenu: [
            {
              submenu: "Add User",
              link: "/users/add"
            },
            {
              submenu: "List Users",
              link: "/users/list"
            },
            {
              submenu: "Permission Sets",
              link: "/users/permission"
            },
            {
              submenu: "Bulk Import",
              link: "/users/import"
            }
          ]
        },
        {
          menuHeader: "Citizen",
          menuLabel: "Citizen",
          img: "/assets/images/cm-menu-icon/m-citizen.svg",
          icon: "pi pi-map",
          sidebarSubMenu: [
            {
              submenu: "All Citizens List",
              link: "/citizen/allcitizens"
            },
            {
              submenu: "Resident Goans",
              link: "/citizen/residentgoans"
            },
            {
              submenu: "Non Resident Goans",
              link: "/citizen/nonresidentgoans"
            },
            {
              submenu: "Non Goan Residents",
              link: "/citizen/nongoanresidents"
            },
            {
              submenu: "Minor Citizens",
              link: "/citizen/minorcitizens"
            }
          ]
        },
        {
          menuHeader: "Campaign",
          menuLabel: "Campaign",
          img: "/assets/images/cm-menu-icon/m-supporttickets.svg",
          icon: "pi pi-volume-up",
          sidebarSubMenu: [
            {
              submenu: "Campaign List",
              link: "/campaign"
            },
            {
              submenu: "Campaign Template",
              link: "/campaigntemplate/pushnotification"
            },
          ]
        },
        {
          menuHeader: "Departments",
          menuLabel: "Departments",
          img: "/assets/images/cm-menu-icon/m-departments.svg",
          icon: "pi pi-users",
          sidebarSubMenu: [
            {
              submenu: "Home",
              link: "/departments/home"
            },
            {
              submenu: "Education",
              link: "/departments/education"
            },
            {
              submenu: "Agriculture",
              link: "/departments/agriculture"
            },
            {
              submenu: "Information Technology",
              link: "/departments/informationtechnology"
            },
            {
              submenu: "Forest",
              link: "/departments/forest"
            },
            {
              submenu: "Handicrafts & Textile",
              link: "/departments/handicraftsandtextile"
            },
            {
              submenu: "Information & Publicity",
              link: "/departments/informationandpublicity"
            },
            {
              submenu: "Excise",
              link: "/departments/excise"
            },
            {
              submenu: "Finance",
              link: "/departments/finance"
            },
            {
              submenu: "Tourism",
              link: "/departments/tourism"
            }
          ]
        },
        {
          menuHeader: "Districts",
          menuLabel: "Districts",
          img: "/assets/images/cm-menu-icon/m-districts.svg",
          icon: "pi pi-map-marker",
          sidebarSubMenu: [
            {
              submenu: "North Goa",
              link: "/districts/northgoa"
            },
            {
              submenu: "South Goa",
              link: "/districts/southgoa"
            }
          ]
        },
        {
          menuHeader: "LocalBodies",
          menuLabel: "Local Bodies",
          img: "/assets/images/cm-menu-icon/m-localbodies.svg",
          icon: "pi pi-tablet",
          sidebarSubMenu: [
            {
              submenu: "Corporation of the City of Panaji",
              link: "/localbodies/corporationcitypanaji"
            },
            {
              submenu: "Margao Municipal Council",
              link: "/localbodies/margaomunicipalcouncil"
            },
            {
              submenu: "Ponda Municipal Council",
              link: "/localbodies/pondamunicipalcouncil"
            },
            {
              submenu: "Bicholim Municipal Council",
              link: "/localbodies/bicholimmunicipalcouncil"
            },
            {
              submenu: "Mormugao Municipal Council",
              link: "/localbodies/mormugaomunicipalcouncil"
            },
            {
              submenu: "Mapusa Municipal Council",
              link: "/localbodies/mapusamunicipalcouncil"
            },
            {
              submenu: "Cuncolim Municipal Council",
              link: "/localbodies/cuncolimmunicipalcouncil"
            },
            {
              submenu: "Pernem Municipal Council",
              link: "/localbodies/pernemmunicipalcouncil"
            },
            {
              submenu: "Quepem Municipal Council",
              link: "/localbodies/quepemmunicipalcouncil"
            },
            {
              submenu: "Curchorem Municipal Council",
              link: "/localbodies/bicholimmunicipalcouncil"
            },
            {
              submenu: "Valpoi Municipal Council",
              link: "/localbodies/valpoimunicipalcouncil"
            },
            {
              submenu: "Canacona Municipal Council",
              link: "/localbodies/canaconamunicipalcouncil"
            }
          ]
        },
        {
          menuHeader: "SupportTicket",
          menuLabel: "Support Ticket",
          img: "/assets/images/cm-menu-icon/m-supporttickets.svg",
          icon: "pi pi-ticket",
          sidebarSubMenu: [
            {
              submenu: "Open Support Tickets",
              link: "/supportticket/open"
            },
            {
              submenu: "Closed Support Tickets",
              link: "/supportticket/closed"
            }
          ]
        },
        {
          menuHeader: "Schemes",
          menuLabel: "Schemes",
          img: "/assets/images/cm-menu-icon/m-schemes.svg",
          icon: "pi pi-sitemap",
          sidebarSubMenu: [
            {
              submenu: "Schemes List",
              link: "/schemes/listing"
            },
            {
              submenu: "Total Beneficaries",
              link: "/schemes/totalbeneficaries"
            },
            {
              submenu: "Discontinued Schemes",
              link: "/schemes/discontinued"
            }
          ]
        },
        {
          menuHeader: "Announcements",
          menuLabel: "Announcements",
          img: "/assets/images/cm-menu-icon/m-announcements.svg",
          icon: "pi pi-comment",
          sidebarSubMenu: [
            {
              submenu: "New Announcement",
              link: "/announcements/new"
            },
            {
              submenu: "Previous Announcements",
              link: "/announcements/previous"
            },
          ]
        },
        {
          menuHeader: "Polls",
          menuLabel: "Polls",
          img: "/assets/images/cm-menu-icon/m-polls.svg",
          icon: "pi pi-align-left",
          sidebarSubMenu: [
            {
              submenu: "New Poll",
              link: "/polls/new"
            },
            {
              submenu: "Polls Conducted",
              link: "/polls/conducted"
            },
          ]
        },
        {
          menuHeader: "Billing",
          menuLabel: "Billing",
          img: "/assets/images/cm-menu-icon/m-billing.svg",
          icon: "pi pi-book",
          sidebarSubMenu: [
            {
              submenu: "Dashboard",
              link: "/billing/dashboard"
            },
            {
              submenu: "Account Statement",
              link: "/billing/accountstatement"
            },
            {
              submenu: "Tax Invoices",
              link: "/billing/taxinvoices"
            },
            {
              submenu: "Payment Receipts",
              link: "/billing/paymentreceipts"
            },
          ]
        },
        {
          menuHeader: "CloudTelephoney",
          menuLabel: "Cloud Telephoney",
          img: "/assets/images/cm-menu-icon/m-localbodies.svg",
          icon: "pi pi-mobile",
          sidebarSubMenu: [
            {
              submenu: "Virtual Numbers",
              link: "/cloudtelephoney/virtualnumbers"
            },
            {
              submenu: "Costum Tunes",
              link: "/cloudtelephoney/CustomTunes"
            }
          ]
        },
        {
          menuHeader: "SystemLogs",
          menuLabel: "System Logs",
          img: "/assets/images/cm-menu-icon/m-systemlogs.svg",
          icon: "pi pi-bookmark",
          sidebarSubMenu: [
            {
              submenu: "SMS Logs",
              link: "/systemlogs/smslogs"
            },
            {
              submenu: "Email Logs",
              link: "/systemlogs/emaillogs"
            },
            {
              submenu: "Bharat Messenger Logs",
              link: "/systemlogs/bharatmessengerlogs"
            },
            {
              submenu: "WebBot Logs",
              link: "/systemlogs/webbotlogs"
            },
            {
              submenu: "Incoming Call Logs",
              link: "/systemlogs/incomingcalllogs"
            },
            {
              submenu: "Outgoing Call Logs",
              link: "/systemlogs/outgoingcalllogs"
            },
            {
              submenu: "Aadhar Logs",
              link: "/systemlogs/drivinglicencelogs"
            },
            {
              submenu: "DL Logs",
              link: "/systemlogs/aadharlogs"
            },
            {
              submenu: "PAN Logs",
              link: "/systemlogs/panlogs"
            },
            {
              submenu: "Passport Logs",
              link: "/systemlogs/passportlogs"
            }
          ]
        },
        {
          menuHeader: "SystemSettings",
          menuLabel: "System Settings",
          img: "/assets/images/cm-menu-icon/m-settings.svg",
          icon: "pi pi-cog",
          sidebarSubMenu: [
            {
              submenu: "API Settings",
              link: "/systemsettings/apisettings"
            },
            {
              submenu: "Citizen Tags",
              link: "/systemsettings/citizentags"
            }
          ]
        },
      ]
    };
  },
  mounted() {
    // this.user_info = this.$store.state.loggedInUserSessions.user;
    // this.host_name = this.user_info.ad3;
    // this.client_info = this.$store.state.loggedInUserSessions.client_info;
    // if (this.baseUrl == 'http://localhost:8080') {
    //   this.client_path = this.client_info.maa24;
    // } else {
    //   this.client_path = this.client_info.maa24;
    // }
    // this.client_logo = "https://storage.googleapis.com/" + this.client_path + "/hostCompany/" + this.client_info.maa6;
  },
};
</script>
