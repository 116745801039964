<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <button
        type="button"
        class="btn notification-btn"
      >
      <img src="/assets/images/new-icon/notification.svg" alt="notification" width="20" height="20" />
      </button>
      <div class="profile-signout-dropdown-outer">
        <div class="dropdown">
          <button
            class="
              btn btn-secondary
              dropdown-toggle
              switch-branch-btn
              text-truncate
              d-inline-block
            "
            type="button"
            id="switchdealerbranch"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img
                v-if="this.user_singal_image == null || this.user_singal_image == ''"
                  src="/assets/images/profile-10.jpg"
                  class="profile-header-avatar rounded-circle"
                  alt=""
                  width="20"
                  height="20"
                />
                <img
                v-if="this.user_singal_image != null && this.user_singal_image != ''"
                  :src="user_image"
                  class="profile-header-avatar rounded-circle"
                  alt=""
                  width="20"
                  height="20"
                />
              </div>
              <div class="flex-grow-1 ms-2 text-capitalize ellipsis-user-name"> {{ user_name }} </div>
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="switchdealerbranch">
            <!-- <li>
              <router-link class="dropdown-item" to="/myprofile"
                ><img src="/assets/images/new-icon/icon-profile-edit.svg" class="profile-menu-icon" alt="my-profile" /><span>My Profile</span></router-link
              >
            </li> -->
            <li>

                <span class="dropdown-item cursor-pointer" @click="logout"
                ><img src="/assets/images/new-icon/icon-profile-signout.svg" class="profile-menu-icon" alt="sign-out" /><span>Sign Out</span></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from "../mixins/commonMixin";
import ApiService from "../service/ApiService";
import { useToast } from 'vue-toast-notification';
import useValidate from "@vuelidate/core";

export default {
  mixins: [ commonMixin ],
  data() {
    const toast = useToast();
    return {
      v$: useValidate(),
      toast: toast,
      user_name: "Kamalakar Patil",
      user_image: "",
      localstorageimgpath: '',
      client_info: '',
      user_singal_image: "",
      user_info: '',
      hostname: '',
      host_logo: null,
      ac18: '',
      usertype: '',
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
            // var localdata = this.$store.state.loggedInUserSessions.user;
            // this.ac18 = localdata.ac18;
            // this.user_name = localdata.ad6 +" "+ localdata.ad8;
            // this.user_singal_image = localdata.ad22;
            // this.client_info = this.$store.state.loggedInUserSessions.client_info;
            // this.user_info = this.$store.state.loggedInUserSessions.user;
            // this.hostname = this.user_info.ad3;
            // this.localstorageimgpath = this.client_info.maa24;
            // this.usertype = this.user_info.ad23;
            // if(this.usertype == 2) {
            //   this.user_image = "https://storage.googleapis.com/"+ this.localstorageimgpath +'/host/userprofile/'+ this.user_singal_image;
            // } else if(this.usertype == 1 ) {
            //   this.user_image = "https://storage.googleapis.com/"+ this.localstorageimgpath +'/admin/userprofile/'+ this.user_singal_image;
            // } else if(this.usertype == 3) {
            //   this.user_image = "https://storage.googleapis.com/"+ this.localstorageimgpath +'/police/userprofile/'+ this.user_singal_image;
            // } else {
            //   this.user_image = '';
            // }
            // this.host_logo = "https://storage.googleapis.com/"+ this.localstorageimgpath +'/host/hostimage/'+ localdata.ac18;
  },
  methods: {
    logout() {
      this.ApiService.logout().then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          // setTimeout(() => { this.message = this.server + ': Reset' }, 0)
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          var errorMsg = data.message;
            this.toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.ellipsis-user-name {
  white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
</style>