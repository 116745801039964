<template>
  <div class="container-fluid" v-if="$route.meta.requiresAuth">
    <div class="row application-bg">
      <SidebarTabs />
      <main class="col-md-9 col-lg-9 ms-sm-auto layout-content-outer">
        <div class="layout-content-inner">
          <router-view />
        </div>
      </main>
    </div>
  </div>
  <div class="container-fluid" v-if="!$route.meta.requiresAuth">
    <router-view />
  </div>
</template>

<script>
import SidebarTabs from "./components/SidebarTabs.vue";

export default {
  name: 'App',
  components: {
    SidebarTabs: SidebarTabs,
    // HelloWorld
  }
}
</script>
