import { createRouter, createWebHistory } from 'vue-router';
// import store from './store'
// import commonFunction from "./composables/commonFunction"
// const { checkEmpty } = commonFunction()
const routes = [
  
  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import( "./pages/AccessCode.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/accesscode',
    name: 'AccessCode',
    exact: true,
    component: () =>
      import('./pages/AccessCode.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: () => import( "./pages/Dashboard.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/add",
    name: "AddUser",
    component: () => import( "./pages/users/AddUser.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/import",
    name: "BulkImport",
    component: () => import( "./pages/users/BulkImport.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/list",
    name: "ListUsers",
    component: () => import( "./pages/users/ListUsers.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/users/permission",
    name: "PermissionSet",
    component: () => import( "./pages/users/PermissionSet.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/citizen/allcitizens",
    name: "AllCitizensList",
    component: () => import( "./pages/citizen/AllCitizensList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/citizen/minorcitizens",
    name: "MinorCitizens",
    component: () => import( "./pages/citizen/MinorCitizens.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/citizen/nongoanresidents",
    name: "NonGoanResidents",
    component: () => import( "./pages/citizen/NonGoanResidents.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/citizen/nonresidentgoans",
    name: "NonResidentGoans",
    component: () => import( "./pages/citizen/NonResidentGoans.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/citizen/residentgoans",
    name: "ResidentGoans",
    component: () => import( "./pages/citizen/ResidentGoans.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/citizen/total-citizen",
    name: "CurrentGuests",
    component: () => import( "./pages/demo/CurrentGuest.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/departments/agriculture",
    name: "Agriculture",
    component: () => import( "./pages/Departments/Agriculture.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/education",
    name: "Education",
    component: () => import( "./pages/Departments/Education.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/excise",
    name: "Excise",
    component: () => import( "./pages/Departments/Excise.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/finance",
    name: "Finance",
    component: () => import( "./pages/Departments/Finance.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/forest",
    name: "Forest",
    component: () => import( "./pages/Departments/Forest.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/handicraftsandtextile",
    name: "HandicraftsAndTextile",
    component: () => import( "./pages/Departments/HandicraftsAndTextile.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/home",
    name: "Homedepartments",
    component: () => import( "./pages/Departments/Home.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/informationandpublicity",
    name: "InformationAndPublicity",
    component: () => import( "./pages/Departments/InformationAndPublicity.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/informationtechnology",
    name: "InformationTechnology",
    component: () => import( "./pages/Departments/InformationTechnology.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/departments/tourism",
    name: "Tourism",
    component: () => import( "./pages/Departments/Tourism.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/districts/northgoa",
    name: "NorthGoaDist",
    component: () => import( "./pages/Districts/NorthGoaDist.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/districts/southgoa",
    name: "SouthGoaDist",
    component: () => import( "./pages/Districts/SouthGoaDist.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/districts/southgoa",
    name: "SouthGoaDist",
    component: () => import( "./pages/Districts/SouthGoaDist.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/bicholimmunicipalcouncil",
    name: "BicholimMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/BicholimMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/canaconamunicipalcouncil",
    name: "CanaconaMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/CanaconaMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/corporationcitypanaji",
    name: "CorporationCityPanaji",
    component: () => import( "./pages/LocalBodies/CorporationCityPanaji.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/cuncolimmunicipalcouncil",
    name: "CuncolimMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/CuncolimMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/mapusamunicipalcouncil",
    name: "MapusaMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/MapusaMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/margaomunicipalcouncil",
    name: "MargaoMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/MargaoMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/mormugaomunicipalcouncil",
    name: "MormugaoMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/MormugaoMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/pernemmunicipalcouncil",
    name: "PernemMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/PernemMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/pondamunicipalcouncil",
    name: "PondaMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/PondaMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/quepemmunicipalcouncil",
    name: "QuepemMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/QuepemMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/localbodies/valpoimunicipalcouncil",
    name: "ValpoiMunicipalCouncil",
    component: () => import( "./pages/LocalBodies/ValpoiMunicipalCouncil.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supportticket/closed",
    name: "ClosedSupportTickets",
    component: () => import( "./pages/SupportTicket/ClosedSupportTickets.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supportticket/open",
    name: "OpenSupportTickets",
    component: () => import( "./pages/SupportTicket/OpenSupportTickets.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/discontinued",
    name: "DiscontinuedSchemes",
    component: () => import( "./pages/Schemes/DiscontinuedSchemes.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/listing",
    name: "SchemesList",
    component: () => import( "./pages/Schemes/SchemesList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schemes/totalbeneficaries",
    name: "TotalBeneficaries",
    component: () => import( "./pages/Schemes/TotalBeneficaries.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/announcements/new",
    name: "NewAnnouncement",
    component: () => import( "./pages/Announcements/NewAnnouncement.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/announcements/previous",
    name: "PreviousAnnouncements",
    component: () => import( "./pages/Announcements/PreviousAnnouncements.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/polls/new",
    name: "NewPoll",
    component: () => import( "./pages/Polls/NewPoll.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/polls/conducted",
    name: "PollsConducted",
    component: () => import( "./pages/Polls/PollsConducted.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/accountstatement",
    name: "AccountStatement",
    component: () => import( "./pages/Billing/AccountStatement.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/dashboard",
    name: "BillingDashboard",
    component: () => import( "./pages/Billing/Dashboard.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/paymentreceipts",
    name: "PaymentReceipts",
    component: () => import( "./pages/Billing/PaymentReceipts.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/taxinvoices",
    name: "TaxInvoices",
    component: () => import( "./pages/Billing/TaxInvoices.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephoney/CustomTunes",
    name: "CostumTunes",
    component: () => import( "./pages/CloudTelephoney/CostumTunes.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephoney/virtualnumbers",
    name: "VirtualNumbers",
    component: () => import( "./pages/CloudTelephoney/VirtualNumbers.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/aadharlogs",
    name: "AadharLogs",
    component: () => import( "./pages/SystemLogs/AadharLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/bharatmessengerlogs",
    name: "BharatMessengerLogs",
    component: () => import( "./pages/SystemLogs/BharatMessengerLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/drivinglicencelogs",
    name: "DLLogs",
    component: () => import( "./pages/SystemLogs/DLLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/emaillogs",
    name: "EmailLogs",
    component: () => import( "./pages/SystemLogs/EmailLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/incomingcalllogs",
    name: "IncomingCallLogs",
    component: () => import( "./pages/SystemLogs/IncomingCallLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/outgoingcalllogs",
    name: "OutgoingCallLogs",
    component: () => import( "./pages/SystemLogs/OutgoingCallLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/panlogs",
    name: "PANLogs",
    component: () => import( "./pages/SystemLogs/PANLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/passportlogs",
    name: "PassportLogs",
    component: () => import( "./pages/SystemLogs/PassportLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/smslogs",
    name: "SMSLogs",
    component: () => import( "./pages/SystemLogs/SMSLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemlogs/webbotlogs",
    name: "WebBotLogs",
    component: () => import( "./pages/SystemLogs/WebBotLogs.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemsettings/apisettings",
    name: "APISettings",
    component: () => import( "./pages/SystemSettings/APISettings.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemsettings/citizentags",
    name: "CitizenTags",
    component: () => import( "./pages/SystemSettings/CitizenTags.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/reports",
    name: "GuestsReport",
    component: () => import( "./pages/demo/GuestsReport.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/branchlist",
    name: "BranchList",
    component: () => import( "./pages/BranchList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chatbot',
    name: 'ChatBotLandingPage',
    exact: true,
    component: () =>
      import('./pages/chatbot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/webbot/:param1',
    name: 'WebBot',
    exact: true,
    component: () =>
      import('./pages/WebBot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/campaign",
    name: "CampaignList",
    component: () => import( "./pages/campaign/CampaignList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/pushlogs/:campaignId?',
    name: 'PushCampaignLogs',
    component: () =>
      import('./pages/campaign/PushCampaignLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/pushnotification',
    name: 'PushNotificationCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/PushNotificationCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/underprogress",
    name: "underprogress",
    exact: true,
    component: () => import( "./pages/UnderProgress.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import( "./pages/NotFound" ),
    meta: {
      requiresAuth: false,
    },
  },

];

const router = createRouter( {
  history: createWebHistory( process.env.BASE_URL ),
  routes,
} );
router.beforeEach( async ( to, from, next ) => {
  const authenticatedUser = localStorage.user_reference;
  const requiresAuth = to.matched.some( ( record ) => record.meta.requiresAuth );
  const checkModuleAccess = to.matched.some( ( record ) => record.meta.checkModuleAccess );
  const moduleId = to.meta.moduleId;
  const permission_fks = [ 1, 11, 12 ];

  if (requiresAuth && !authenticatedUser) {
    next( "/" );
  } else {
    if ( checkModuleAccess ) {
      if ( permission_fks.includes( moduleId ) ) {
        next();
      } else {
        next( false );
      }
    } else {
      next();
    }
  }
} );

export default router;